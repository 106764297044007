import React from "react"
import { StaticImage } from "gatsby-plugin-image"


import Layout from "../components/layout"
import SEO from "../components/seo"

const services = [{id: 0, title: "Control4® Smart Homes"},{id: 1, title: "Networking & Wi-Fi"}, {id: 2, title: "Broadband & Connectivity"}, {id: 3, title: "IT Hardware, Software & Support"}];

const IndexPage = () => (
  <Layout>
    <SEO title="Home" />
    <div className="grid md:grid-cols-2 mt-6 sm:mt-0 place-items-center h-full">
      <div className="lg:p-24 sm:p-12 mb-8 md:mb-0">
        <p className="text-3xl">Need some help making your home or business <span className="text-red font-medium">smarter</span>?</p>
        <p className="pb-3 pt-6">We specialise in:</p>
        <ul className="list-inside list-disc">
            {services.map(service => (
                <li key={service.id}>{service.title}</li>
            ))}
        </ul>
        <div className="pt-6">
          <a className="button" href="mailto:hello@wilkio.com?subject=Web Contact Request">Contact us</a>
        </div>
      </div>
      <div className="w-4/6 md:w-full">
      <StaticImage
        src="https://wilkio.com/wp-content/uploads/2021/09/wilkio_dw.png"
        alt="Wilkio Limited"
        placeholder="none"
        />
      </div>
    </div>
  </Layout>
)

export default IndexPage
